let landingpage = {

  init: function() {
    this.whatsLink()
    
    this.scrollSuave()
    this.restrictedArea()
    this.maskphone()

    if($('body').hasClass('obrigado')){
      landingpage.conversionTag()
    }

  },
  maskphone: function() {
    var telMask = ['(99) 99999-9999', '(99) 99999-9999'];
    var tel = document.querySelector('#formphone');
    VMasker(tel).maskPattern(telMask[0]);
    // tel.addEventListener('input', tel.inputHandler.bind(undefined, telMask, 14), false);
  },

  scrollSuave: function() {
    $('a[href^="#"]').on('click', function(e) {
      e.preventDefault();
      var id = $(this).attr('href'),
        targetOffset = $(id).offset().top;

      $('html, body').animate({
        scrollTop: targetOffset
      }, 500);
    });
  },
  whatsLink: function() {
    var tel = '5511966305687'
    if ($(window).width() > 768) {
      $('.social__whatsapp').attr('href', 'https://web.whatsapp.com/send?phone=' + tel)
    } else {
      $('.social__whatsapp').attr('href', 'https://wa.me/' + tel)
    }
    
    $('.social__whatsapp').click(function(){
      landingpage.conversionWhats()
    })
    
  },
  restrictedArea: function() {
    // var restrictedAreaButton = document.querySelector('');

    document.addEventListener('click', function(event) {

      // If the clicked element doesn't have the right selector, bail
      if (!event.target.matches('.header__restricted-area')) return;

      // Don't follow the link
      event.preventDefault();

      // Log the clicked element in the console
      console.log(event.target);

        // });

        const {
            value: formValues
          } = Swal.fire({
            title: 'Faça o Login',
            html: `<div class="swal2-radio" style="display: flex;">
                        <label id="swal-radio1">
                            <input style="-webkit-appearance:textfield" type="radio" name="swal2-radio" value="#ff0000" class="">
                            <span class="swal2-label">Beneficiario</span>
                        </label>
                        <label id="swal-radio2">
                            <input style="-webkit-appearance:textfield" type="radio" name="swal2-radio" value="#ff0000" class="">
                            <span class="swal2-label">Prestador</span>
                        </label>
                        </div>
                        <label>
                            <span class="swal2-label">CPF</span>
                            <input id="swal-input3" type="text" class="swal2-input" placeholder="CPF" maxlength="14">
                        </label>
                        <label>
                            <span class="swal2-label">Senha</span>
                            <input id="swal-input4" type="password" class="swal2-input" placeholder="Senha">
                        </label>`,
            focusConfirm: false,
            preConfirm: () => {
              return {
                  allEmpty: !document.querySelector('#swal-radio1 input').checked && !document.querySelector('#swal-radio2 input').checked && document.getElementById('swal-input3').value == "" && document.getElementById('swal-input4').value == "",
                  radio1Checked = document.querySelector('#swal-radio1 input').checked,
                  radio2Checked = document.querySelector('#swal-radio2 input').checked,
                  cpfValue = document.getElementById('swal-input3').value,
                  senhaValue = document.getElementById('swal-input4').value,
                  allFilled = (document.querySelector('#swal-radio1 input').checked || !document.querySelector('#swal-radio2 input').checked) && document.getElementById('swal-input3').value != "" && document.getElementById('swal-input4').value != "",
              }
            }
          }).then((result) => {
              console.log(result)
              console.log(result.isConfirmed)
              var allEmpty = result.value.allEmpty;
              var allFilled = result.value.allFilled;

              if (allEmpty) {
                    Swal.fire({
                      type: `error`,
                      title: `Usuário não liberado.`,
                      text: `Tente novamente e preencha todos os campos do login.`,
                    })
                }else if (allFilled) {
                    Swal.fire({
                      type: `success`,
                      title: `Acesso liberado!`,
                      text: `Você já pode navegar normalmente.`,
                    })
                }else {
                    Swal.fire({
                      type: `error`,
                      title: `Ocorreu um erro`,
                      text: `Por favor tente novamente.`,
                    })
              }
            })
    
    }, false);
    
  },
  conversionTag: function(){
    
    gtag('event', 'conversion', {'send_to': 'AW-587391712/rcgMCMGUzuEBEODFi5gC'});
   
  },
  conversionWhats: function(){

    function gtag_report_conversion(url) {
      var callback = function () {
        if (typeof(url) != 'undefined') {
        window.location = url;
      }
    };
    
    gtag('event', 'conversion', {
      'send_to': 'AW-587391712/GwihCLOUv-EBEODFi5gC',
      'event_callback': callback
    });
    return false;
    }
    var url;
    gtag_report_conversion(url)
    
  }
}
landingpage.init()