document.addEventListener('DOMContentLoaded', function() {

  document.querySelector('#send-contact').addEventListener('submit', function(e) {
    debugger
    e.preventDefault();
    let data = new FormData()
    data.append('nome', document.querySelector('#formname').value);
    data.append('telefone', document.querySelector('#formphone').value);
    // data.append('assunto',document.querySelector('#send-contact').getAttribute('data-page'));
    // data.append('texto',document.querySelector('#formmessage').value);

    if (document.querySelector('#formname').value == "" && document.querySelector('#formemail').value == "" && document.querySelector('#formphone').value == "") {
      Swal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Você precisa preencher todos os campos.',
        timer: 2000
      }).then(() => $("#formsend").val('Enviar'));
    } else {

      $("#formsend").val('Enviando')
      fetch('../../mailer.php', {
          method: 'post',
          body: data
        })
        .then(function(resp) {
          if (resp.status == 200) {

            landingpage.conversionTag()

            if (!$('body').hasClass('contato')) {
              Swal.fire({
                type: 'success',
                title: 'Obrigado',
                text: 'Logo entraremos em contato'
              })
              $("#formsend").val('Enviar')
            } else {
              $('body').addClass('contato--finished');
            }
          }
        })
    }
  });


})